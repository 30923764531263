@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --bg-primary: hsl(27, 73%, 98%);
  --bg-secondary: hsl(30, 15%, 90%);
  --bg-tertiary: #f6f1ee;
  --bg-white: #fff;
  --text-primary: #2b342e;
  --text-secondary: #586a5e;
  --text-tertiary: #999897;
  --accent: #476250;
  --bg-gradient: linear-gradient(
    180deg,
    #fdf7f2 0%,
    #fff 99.96%
  ); /* --accent: #476250; */
}

.wavy-div {
  /* height: 600px; */
  background: linear-gradient(to bottom, var(--bg-primary), var(--bg-white));
  clip-path: path("M0,50 C150,100 300,0 450,50 C600,100 750,0 900,50 C1050,100 1200,0 1440,50 L1440,200 L0,200 Z");
}

.dark {
  --bg-primary: #1b1f1c;
  --bg-secondary: #2a2f2c;
  --bg-tertiary: #3a3f3c;
  --text-primary: #e8e8e5;
  --text-secondary: #c1c9c2;
  --text-tertiary: #9aa49b;
  --accent: #8bb398;
  --bg-white: hsl(135, 7%, 10%);
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
  color: var(--text-primary);
  line-height: 1.8;
}

.gradient-bg {
  background: linear-gradient(to top, var(--bg-primary), transparent);
}

.shadow-inner-custom {
  box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.05),
    inset 0 -2px 4px 0 rgba(0, 0, 0, 0.1);
}
